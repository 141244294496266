import { onCLS } from 'web-vitals/attribution';

const sendToAnalytics = (metric) => {
  const {
    value,
    rating,
    attribution: {
      largestShiftTarget: target,
    },
  } = metric;

  // Track CLS if the metric is not good.
  if ('good' !== rating && 'undefined' !== typeof s) {
    const {
      location: {
        host,
        pathname,
      },
    } = window;

    let shortUrl = 'unknown';
    const matches = pathname.match(/\/([0-9]+)\//);
    const postId = matches ? matches[1] : null;

    if (postId) {
      shortUrl = `${host}?p=${postId}`;
    }

    const eVar120 = [
      target, // The element that shifted.
      value.toFixed(2), // Round the CLS value to 2 digits.
      window.innerWidth, // Device width to better understand what breakpoint this occurs at.
      shortUrl, // URL where issue happened.
    ];

    s.tl(
      true,
      'o',
      'CLS',
      {
        linkTrackVars: 'eVar120',
        eVar120: eVar120.join('|'),
      }
    );
  }
};

onCLS(sendToAnalytics);
